import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: "/",
        component: () => import("../views/layout/Layout.vue"),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('../views/Home')
            },
            {
                path: '/privacy-policy',
                name: 'PrivacyPolicy',
                component: () => import('../views/PrivacyPolicy')
            },
            {
                path: '/terms-condition',
                name: 'TermsAndCondition',
                component: () => import('../views/TermsAndCondition')
            },
            {
                path: '/refund-and-cancellation',
                name: 'RefundAndCancellation',
                component: () => import('../views/RefundAndCancellation')
            },
            {
                path: '/game-rules',
                name: 'GameRules',
                component: () => import('../views/GameRules')
            }
        ]
    },
    {
        path: "/:pathMatch(.*)",
        name: "NotFoundComponent",
        component: () => import("../views/errors/NotFoundComponent.vue"),
    },


]

const scrollBehavior = (to) => {
    if (to.hash) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const element = document.querySelector(to.hash);
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop,
                        behavior: 'smooth',
                    });
                }
                resolve();
            }, 300); // Adjust delay as needed
        });
    }
    return { left: 0, top: 0 }; // Default scroll to top
};



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior
});
export default router
